<template>
  <ValidationObserver @submit="assignTeaher">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Teacher Enrollment</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="enrollmentData.campusId"
            title="CAMPUSES"
            label="title"
            :options="campuses"
            class="flex-1"
            reduce="id"
            rules="required"
            :search-through-api="true"
            :disabled="!!currentCampusScope"
            @search="getCampus"
            @change="getClasses(enrollmentData.campusId)"
            @deSelect="getClasses(enrollmentData.campusId)"
          />
          <UiSingleSelect
            v-model="enrollmentData.class_id"
            title="Class"
            :options="classScopeList"
            label="title"
            reduce="id"
            class="flex-1"
            :search-through-api="true"
            :disabled="!!currentClassScope || !enrollmentData.campusId"
            @search="getClass"
            @change="setSection(enrollmentData.class_id)"
            @deSelect="setSection(enrollmentData.class_id)"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="enrollmentData.sectionId"
            title="SECTS"
            label="title"
            class="flex-1"
            reduce="id"
            :rules="sectionRules"
            :disabled="currentSectionScope || !enrollmentData.class_id"
            :options="usersSectionList"
            :search-through-api="true"
            @change="loadSubject"
            @deSelect="loadSubject"
            @search="getSection"
          />
          <UiSingleSelect
            v-model="enrollmentData.subjectId"
            title="SUBJS"
            label="title"
            :options="subjectsList"
            class="flex-1"
            :disabled="!enrollmentData.sectionId"
            :search-through-api="true"
            reduce="id"
            @search="loadSubject"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span>Assign</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import { removeEmptyKeysFromObject } from '@src/utils/generalUtil.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import { objectDeepCopy } from '@utils/generalUtil'

export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    currentUser: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      subjectsList: [],
      classScopeList: [],
      enrollmentData: {
        campusId: '',
        sectionId: '',
        teacherId: '',
        subjectId: '',
        class_id: '',
      },
    }
  },
  computed: {
    ...mapState('layout', [
      'currentCampusScope',
      'currentSectionScope',
      'campuses',
      'currentClassScope',
    ]),
    ...mapState('section', ['usersSectionList']),
    ...mapState({ isLoading: (state) => state.layout?.isLoading }),
    sectionRules() {
      return this.currentClassScope || this.enrollmentData.class_id ? 'required' : ''
    },
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(value) {
        if (value) this.enrollmentData.teacherId = this.currentUser?.id
      },
    },
    'enrollmentData.campusId': {
      handler() {
        if (!this.currentCampusScope) this.enrollmentData.class_id = ''
      },
    },

    'enrollmentData.sectionId': {
      handler() {
        this.enrollmentData.subjectId = ''
        this.subjectsList = []
        this.loadSubject()
      },
    },
    'enrollmentData.class_id': {
      handler() {
        if (!this.currentSectionScope) this.enrollmentData.sectionId = ''
        this.enrollmentData.subjectId = ''
        this.subjectsList = []
        this.setSection(this.enrollmentData?.class_id)
      },
    },
  },
  created() {
    if (this.currentCampusScope) {
      this.enrollmentData.campusId = this.currentCampusScope.id
      this.getClasses(this.enrollmentData.campusId)
    }

    if (this.currentClassScope) {
      this.enrollmentData.class_id = this.currentClassScope.id
      this.setSection(this.enrollmentData.class_id)
    }
    if (this.currentSectionScope) {
      this.enrollmentData.class_id = this.currentClassScope.id
      this.enrollmentData.sectionId = this.currentSectionScope.id
      this.loadSubject()
    }
  },
  methods: {
    getSection(query = '') {
      this.setSection(this.enrollmentData?.class_id, query)
    },
    setSection(id, query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', id || this.currentClassScope?.id),
        },
      }
      this.filterUsersSectionsList(payload)
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          this.enrollmentData.teacherId = ''
          break
      }
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },
    getClass(query = '') {
      this.getClasses(this.enrollmentData?.campusId, query)
    },
    async getClasses(id, query) {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', id || this.currentCampusScope?.id),
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classScopeList = await this.getUserClasses(payload)
      this.isSelectedClassExist()
    },
    isSelectedClassExist() {
      const isClassPresent = this.classScopeList.some(
        (classItem) => classItem.id === this.currentClassScope?.id,
      )

      if (this.currentClassScope && !isClassPresent)
        this.classScopeList.push(this.currentClassScope)
    },
    /**
     * Get section bases subject
     */
    loadSubject(query = '') {
      this.classSubjects({
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'class_id',
            this.enrollmentData?.class_id || this.currentClassScope?.id,
          ),
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'section_id',
            this.enrollmentData?.sectionId || this.currentSectionScope?.id,
          ),
        },
      }).then((response) => {
        this.subjectsList = response?.data?.records
      })
    },
    /**
     * Assign teacher to any campus, section or subject
     */
    assignTeaher() {
      this.$store.commit('layout/IS_LOADING', true)

      let payload = {
        campus_id: this.enrollmentData.campusId,
        section_id: this.enrollmentData.sectionId,
        teacher_id: this.enrollmentData.teacherId,
        subject_id: this.enrollmentData.subjectId,
        class_id: this.enrollmentData.class_id,
      }
      removeEmptyKeysFromObject(payload)
      this.teacherAssignment(payload).then((response) => {
        this.$emit('toggle', true)
        this.resetForm()
      })
    },

    resetForm() {
      this.enrollmentData.campusId = ''
      this.enrollmentData.sectionId = ''
      this.enrollmentData.teacherId = ''
    },
    ...mapActions('teacher', ['teacherAssignment']),
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('classes', ['getUserClasses']),
    ...mapActions('section', ['filterUsersSectionsList']),
    ...mapActions('layout', ['getCampusList']),
  },
}
</script>
